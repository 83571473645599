import { Card, CardContent, CardHeader } from "@mui/material";
import { ImageInput, ImageField } from 'react-admin';
import React, { useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone'
import './dashboard.scss'
// import { useState, useEffect } from "react";

export function Dashboard() {
    const [file, setFile] = useState()
    const [uploadedFileURL, setUploadedFileURL] = useState(null)

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    function handleSubmit(event) {
        event.preventDefault()
        const loc = document.location;
        const url = `https://ccx-store.com/api/image`
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        fetch(url, {
            method: 'POST',
            headers: { 'Access-Control-Allow-Origin': '*' },
            body: formData
        })
    }

    return (
        <div className="App">
            <form onSubmit={handleSubmit}>
                <h1>Загрузить изображения продуктов</h1>
                <input type="file" onChange={handleChange} />
                <button type="submit">Загрузить</button>
            </form>
            {uploadedFileURL && <img src={uploadedFileURL} alt="Uploaded content" />}
        </div>
    );

}
