import { stringify } from 'query-string';


const loc = document.location;
const apiUrl = `https://ccx-store.com/api`
// const apiUrl = `http://${loc.hostname}:8080/api`
// const apiUrl = `http://${loc.hostname}:8080/api`
// const apiUrl = "https://ccx-store.com/api"
// const apiUrl = ""


const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
        // sort: JSON.stringify([field, order]),
        // range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        // filter: JSON.stringify(params.filter),
    };
    console.log(apiUrl)
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetch(url, {method: "GET", headers: {'Access-Control-Allow-Origin': '*'}
    })
    .then(data => data.json())
    .then(response => {
        return { data: response, total: 1000000}})
      .catch(err => console.error(err));
  },


  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`
    return fetch(url, {
      method: 'GET',
      headers: {'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Private-Network': 'true'}
    })
    .then(data => data.json())
    .then(response => {
        return { data: response}})
      .catch(err => console.error(err));
  },


  create: async (resource, params) => {
    const url = `${apiUrl}/${resource}`
    return fetch(url, {
      method: "POST",
      headers: {'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Private-Network': 'true', 'Content-Type': 'application/json'},
      body: JSON.stringify(params.data)
    })
    .then(data => data.json())
    .then(response => {return {data: response}})
  },


  update: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`
    return fetch(url, {
      method: "PUT",
      headers: {'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Private-Network': 'true', 'Content-Type': 'application/json'},
      body: JSON.stringify(params.data)
    })
    .then(data => data.json())
    .then(response => {return {data: response}})
  },


  getMany: async (resource, params) => {
    const query = {
      ids: JSON.stringify(params.ids),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetch(url, {
      method: 'GET',
      headers: {'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Private-Network': 'true'}
    })
    .then(data => data.json())
    .then(response => {
        return { data: response}})
      .catch(err => console.error(err));
  },


  getManyReference: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
        // reference_id: params.id,
        [params.target]: params.id
    };
    // const url = `${apiUrl}/${resource}/?${stringify(query)}`;
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return fetch(url, {
      method: 'GET',
      headers: {'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Private-Network': 'true'}
    })
    .then(data => data.json())
    .then(response => {
      return { data: response, total: 100}})
    .catch(err => console.error(err));
},


delete: async (resource, params) => {
  const url = `${apiUrl}/${resource}/${params.id}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Private-Network': 'true'}
  })
  .then(data => data.json())
  .then(response => {
      return { data: response}})
    .catch(err => console.error(err));

  },

}

export default dataProvider;

