import "./index.scss"
import ccxLogo from "../../img/svg/CCX_logo.svg"
import instaLogo from "../../img/svg/instagram_logo.svg"
import tgLogo from "../../img/svg/telegram_logo.svg"
import vkLogo from "../../img/svg/vk_logo.svg"
import React, { useState, useEffect, useContext } from "react";

const loc = document.location;

async function fetchJSON(url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });

  if (!response.ok)
    throw new Error(`Request at ${url} failed`);

  return await response.json();
}


export function Footer() {
  const [categoryInfo, setCategoryInfo] = useState()

  async function fetchCategory() {
    // return fetchJSON("/category");
    // await ADD
    return await fetchJSON(`https://ccx-store.com/api/category`, {
      headers: {
        "access-control-request-private-network": true
      }
    })
  }

  useEffect(() => {
    (async () => {
      const fetchedCategoryInfo = await fetchCategory();
      setCategoryInfo(fetchedCategoryInfo);
    })();
  }, []);

  if (categoryInfo == undefined) { return }

  return (
    <footer className="footer">
      {/* <div id="portal"></div> */}
      <div className="wrapper">
        <div className="footer__container">
          <img className="footer__logo" src={ccxLogo} />
          <div className="footer__categories-column">
            <div className="footer__categories-label">Товары</div>
            <div className="footer__categories-list">
              {categoryInfo.map((category) => {
                return (
                  <div className="footer__categories-item" key={category.id}>{category.label}</div>
                )
              })}
            </div>
          </div>

          <div className="footer__categories-column">
            <div className="footer__categories-label">Разделы</div>
            <div className="footer__categories-list">
              <div className="footer__categories-item">Товары</div>
              <div className="footer__categories-item">Авторы</div>
              <div className="footer__categories-item">Оплата & доставка</div>
              <div className="footer__categories-item">О нас</div>
            </div>
          </div>

          <div className="footer__categories-column">
            <div className="footer__categories-label">Контакты</div>
            <div className="footer__categories-list">
              <a href="tel:+7 (993) 499-56-09" className="footer__link-to">
                <div className="footer__categories-item">+7 (993) 499-56-09</div>
              </a>
              <a href="mailto:ccx.sup@gmail.com" className="footer__link-to">
                <div className="footer__categories-item">ccx.sup@gmail.com</div>
              </a>
            </div>
          </div>

          <div className="footer__categories-column">
            <div className="footer__categories-label">Мы в социальных сетях</div>
            <div className="footer__categories-list">
              <a href="https://t.me/store_ccx" className="footer__social-link"><img className="footer__social-logo" src={tgLogo} /></a>
              {/* <a href="https://www.instagram.com/ccx_store" className="footer__social-link"><img className="footer__social-logo" src={instaLogo} /></a> */}
              {/*             <a><img className="footer__social-logo" src={vkLogo}/></a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
