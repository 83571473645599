import './index.scss'
import React, { useState, useEffect } from "react";
import { Backdrop } from './backDrop';

export const ContactModal = ({ onClose }) => {
  const [formData, setFormData] = useState({
    formUsername: '',
    contactInfo: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://ccx-store.com/api/form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        console.log('Данные успешно отправлены');
        onClose();
      } else {
        console.error('Ошибка при отправке данных');
        console.log(JSON.stringify(formData))
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
  };

  return (
    <div>
      <Backdrop onClose={onClose} />
      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <h2>Заявка</h2>
          <p className='modal__descr'> Здесь Вы можете написать о том, чтобы хотели видеть на сайте, заказать работу или просто поделиться впечатлением. Все подробно распишите в поле ниже</p>
          <form onSubmit={handleSubmit} className='modal__body'>
            <input className='modal__input-fld' name="formUsername" placeholder="Как к Вам обращаться?" value={formData.formUsername} onChange={handleChange} required />
            <input className='modal__input-fld' name="contactInfo" placeholder="Как с Вами связаться?" value={formData.contactInfo} onChange={handleChange} required />
            {/* <input className='modal__input-fld' name="contactInfo" placeholder="Ваше сообщение" value={formData.message} onChange={handleChange} required /> */}
            <textarea className='modal__input-area' name="message" placeholder="Ваше сообщение" value={formData.message} onChange={handleChange} rows={4} cols={50} required></textarea>
            <button className='modal__btn' type="submit">Отправить</button>
          </form>
        </div>
      </div>
    </div>
  );
};
