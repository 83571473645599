import './index.scss'
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

const loc = document.location;

async function fetchJSON (url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });

  if (!response.ok)
    throw new Error(`Request at ${url} failed`);

  return await response.json();
}

export function AuthorPage(){
  const { authorId } = useParams()
	const [authorInfo, setAuthorInfo] = useState()

  async function fetchAuthor (authorId) {
    return await fetchJSON(`https://ccx-store.com/api/authors/${authorId}`, { headers: {
      "access-control-request-private-network": true
    } })
}

useEffect(() => {
  (async () =>{
    const fetchedAuthorInfo = await fetchAuthor(authorId);
		setAuthorInfo(fetchedAuthorInfo);
  })();
}, []);

	if (authorInfo == undefined) {return}

    return (
			<section className='author'>
				<div className="wrapper">
					<div className='author__container'>
						<div className="author__info">
							<h1 className='author__name'>{authorInfo.firstName}</h1>
							<h1 className='author__surname'>{authorInfo.lastName}</h1>
							<h3 className='author__bio'>{authorInfo.info}</h3>
						</div>
						<div className="author__background">
							<img className='author__photo' src={`https://ccx-store.com/api/get-image/${authorInfo.photo}`}/>
						</div>
					</div>

					<h1>Работы автора:</h1>

					<div className="products__container">
              {authorInfo.products.map((product) => {return (
                <Link to={`../products/${product.id}`} style={{ textDecoration: 'none' }} key={product.id}>
                  {/* <a className="link_for_product_card_to_new_icon_with_full_info" href=""> */}
                    <div className="products__card">
                      <div className="products__card-background">
                        <img src={`https://ccx-store.com/api/get-image/${product.image}`} className="products__card-image"/>
                      </div>
                      <div className="products__card-info">
                        <h2 className="products__card-name">{product.name}</h2>
                        {/* <h3 className="products__card-author">Донская Шура</h3> */}
                        <h1 className="products__card-price">{product.price} ₽</h1>
                      </div>
                    </div>
                </Link>
							)})}
						</div>
				</div>
			</section>
    )
}
