// import './index.css'
import './index.scss'

import ccxLogo from "../../img/svg/CCX_logo.svg"
import phone from "../../img/phone-call.png"
import mail from "../../img/mail.png"
import { Link } from 'react-router-dom';
import tg from "../../img/telegramHeader.png"

export function Header() {
  return (
    <header className="header">
      <div className="wrapper">
        <div className="header_wrapper">
          <div className="header_logo">
            <a href="/" className="header_logo-link">
              {/* <img src="./img/svg/CCX_logo.svg"  alt="CCX" className="header_logo-pic"/> */}
              <img src={ccxLogo} alt="CCX" className="header_logo-pic" />
            </a>
          </div>
          <nav className="header_nav">
            <ul className="header_list">
              <li className="header_item">
                <Link to={"../"} style={{ textDecoration: 'none' }} className='header_link'>
                  товары
                </Link>

              </li>
              <li className="header_item">
                <Link to={"../authors"} style={{ textDecoration: 'none' }} className='header_link'>
                  авторы
                </Link>

              </li>
              <li className="header_item">
                <Link to={"../delivery"} style={{ textDecoration: 'none' }} className='header_link'>
                  доставка
                </Link>

              </li>
              <li className="header_item">
                <Link to={"../about-us"} style={{ textDecoration: 'none' }} className='header_link'>
                  о нас
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header__contacts">
            <div className="header__section">
              <a href="tel:+7 (993) 499-56-09">
                <img className='header__contacts__logo' src={phone}></img>
              </a>
            </div>
            <div className="header__section">
              <a href="mailto:ccx.sup@gmail.com">
                <img className='header__contacts__logo' src={mail}></img>
              </a>
            </div>
            <div className="header__section">
              <a href="https://t.me/ccx_com">
                <img className='header__contacts__logo' src={tg}></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
