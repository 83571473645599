import { List, TextField, Datagrid, SelectInput, Create, SimpleForm, ReferenceInput, TextInput, Edit, ReferenceField, ImageField, ImageInput } from "react-admin";
import { useState, useEffect } from "react";

const loc = document.location;

export const productList = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        {/* <ReferenceField source="idOfAuthor" reference="author"/> */}
        <TextField source="idOfAuthor"/>
        <TextField source="name"/>
        <TextField source="height"/>
        <TextField source="width"/>
        <TextField source="price"/>
        <TextField source="color"/>
        <TextField source="description"/>
        <TextField source="image"/>
      </Datagrid>
    </List>
  )
};

export function ProductCreate(props){
  const [images, setImages] = useState([])
  useEffect(() => {
    fetch(`https://ccx-store.com/api/img`)
      .then(data => data.json())
      .then(res => {setImages(res)})
      .catch(err => console.error(err));
  }, [])
  return (
  <Create {...props}>
    <SimpleForm>
      {/* authors - endpoint на Backend */}
      <ReferenceInput source="idOfAuthor" reference="authors"/>
      <ReferenceInput source="idOfCategory" reference="category"/>
      <TextInput source="name"/>
      <TextInput source="height"/>
      <TextInput source="width"/>
      <TextInput source="price"/>
      <TextInput source="color"/>
      <TextInput source="description" multiline rows={5}/>
      {
        <SelectInput source="image" choices={images?.map(image => ({id: image, name: image}))}/>
      }
    </SimpleForm>
  </Create>
  )
};


export const productEdit = () => (
  <Edit>
      <SimpleForm>
      <TextInput source="id" disabled />
      <ReferenceInput source="authorId" reference="authors" />
      <ReferenceInput source="categoryId" reference="category" />
      <TextInput source="name"/>
      <TextInput source="height"/>
      <TextInput source="width"/>
      <TextInput source="price"/>
      <TextInput source="color"/>
      <TextInput source="description" multiline rows={5}/>
      </SimpleForm>
  </Edit>
);
