import { createContext, useState, useEffect } from 'react'


export const CategoryContext = createContext(null)

const loc = document.location;

async function fetchJSON (url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });
  if (!response.ok)
    throw new Error(`Request at ${url} failed`);
  return await response.json();
}

export function CategoryDataProvider({ children }){
  const [categoryData, setCategoryData] = useState([])

  async function fetchCategories () {
    // return fetchJSON("/category");
    return await fetchJSON(`https://ccx-store.com/api/category`, { headers: {
      "access-control-request-private-network": true
    } })
  }

  useEffect(() => {
    (async () =>{
      const fetchedCategoryInfo = await fetchCategories();
      setCategoryData(fetchedCategoryInfo);
    })();
  }, []);

  return (
    <CategoryContext.Provider value={categoryData}>
      {children}
    </CategoryContext.Provider>
  )
}
