import './index.scss'
import React, { useState, useEffect } from 'react';
import introPic from "../../img/cup_books.png"
import introImage1 from "../../img/ceramicsIntro.PNG"
import introImage2 from "../../img/paintingIntro.JPG"
import introImage3 from "../../img/peoplePainting.JPG"
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ContactModal } from "./../ContactModal";

export function Intro() {

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Функция для открытия модального окна
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Функция для закрытия модального окна
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const slides = [
    {
      image: introImage1,
      title: "Керамика лепится руками и душой",
      description: "Загляните в мир творчества, где руки художника превращают глину в изысканные шедевры"
    },
    {
      image: introImage2,
      title: "Художники пишут, излагая эмоции",
      description: "Рисование - это не только движения кисти по холсту, это воплощение души через каждую линию и каплю краски. Это магия, в которой руки художника танцуют с вдохновением, чтобы создать произведение искусства, наполненное чувствами и смыслом"
    },
    {
      image: introImage3,
      title: "Вдохновением будет осыпан трудолюбивый",
      description: "Труд - это не просто выполнение задач, это исполнение обязанностей с сердцем и решимостью. Каждый шаг, каждое усилие - это строительный блок, который возводит здание достижений. \n\n Это процесс, где руки превращаются в инструменты, а упорство и самоотверженность - в двигатели прогресса. В труде мы находим смысл и удовлетворение, внося свой вклад в общее благополучие и рост"
    }
  ]

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  return (
    <div className="slider-container">
      {isModalOpen && <ContactModal onClose={closeModal} />}
      <TransitionGroup className="slider">
        <CSSTransition key={currentIndex} timeout={300} classNames="slide">
          <div className="slide">
            <div className="slide__img-container">
              <img src={slides[currentIndex].image} className="slide__img" alt={`Slide ${currentIndex}`} />
            </div>
            <div className="slide__text-container">
              <div className="border-wrapper">
                <div className="slide__text-wrapper">
                  <p className="slide__title">{slides[currentIndex].title}</p>
                  <p className="slide-description">{slides[currentIndex].description}</p>
                </div>
              </div>
                <button className='slide__form-btn' onClick={openModal}>Оставить заявку</button>

                <p className='slide__cpy-wrt'>© Союз Современных Художников</p>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <button onClick={prevSlide} className="prev-btn">&#10094;</button>
      <button onClick={nextSlide} className="next-btn">&#10095;</button>
    </div>
  );
}
