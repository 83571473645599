import './index.scss'
import { useState } from "react";
import { DropdownMenu } from './DropDownMenu.jsx';
import { SearchBar } from './SearchBar.jsx';
// import classNames from "classnames";


export function Search(){
	const [isMenuOpened, setIsMenuOpened] = useState(false)


	return (
    <section className='search'>
			<div className="wrapper">
				<div className="modal-windows">
					<DropdownMenu menuSettings={{ isMenuOpened, setIsMenuOpened }}/>
					{/* <SearchBar/> */}
				</div>
			</div>
    </section>
    )
}
