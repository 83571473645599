import './index.scss'
import search from "../../img/search.png"
import { useContext, useState } from 'react'
import { CategoryContext } from '../../context/CategoryContext'
import { ActualProductContext } from '../../context/ActualProductContext'

const loc = document.location;

async function fetchJSON (url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });

  if (!response.ok)
    throw new Error(`Request at ${url} failed`);

  return await response.json();
}

export function SearchBar() {
  const { setActualProductData } = useContext(ActualProductContext)
  const [value, setValue] = useState("")

  async function fetchCategory (value) {
    // return fetchJSON("/category");
    return await fetchJSON(`https://ccx-store.com/api/products-contain/${value}`, { headers: {
      "access-control-request-private-network": true
    } })
  }


  const handleSearchClick = async (event) => {
    event.preventDefault()
    const fetchedCategoryInfo =  await fetchCategory(value);
      const psevdoCategory = {label: "результаты поиска", id: 0, slogan: "", products: fetchedCategoryInfo}
      // setCategoryInfo(fetchedCategoryInfo);
      setActualProductData([psevdoCategory])

   // ---------- рабочий способо --------------------
    // const url = `/products-contain/${value}`
    // fetch(url)
    //   .then(response => {
    //     return response.json()
    //   })
    //   .then(data => {
    //     const psevdoCategory = {label: "результаты поиска", id: 0, slogan: "", products: data}
    //     setActualProductData([psevdoCategory])
    //   })
  }

  function handleChange(event) {
    setValue(event.target.value)
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      console.log("enter was clicked")
      const fetchedCategoryInfo =  await fetchCategory(value);
      const psevdoCategory = {label: "результаты поиска", id: 0, slogan: "", products: fetchedCategoryInfo}
      // setCategoryInfo(fetchedCategoryInfo);
      setActualProductData([psevdoCategory])
    }
  }

  return (
    <form className='search__form'>
      <input
        value={value}
        onChange={handleChange}
        placeholder="Поиск по товарам"
        className="search__field"
        // onKeyDown={handleKeyDown}
      />
      <button type="submit" onClick={handleSearchClick} className="search__btn">
        <img
          className="search__icon"
          src={search}
          alt="search icon"
        />
      </button>
    </form>
  )
}
