import {Header}  from "./components/Header";
import  {Footer}  from "./components/Footer";
import {Intro} from "./components/Intro";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AdminPanel } from "./AdminPanel/App";
import { ProductPage } from "./components/ProductPage";
import ScrollToTop from "./ScrollToTop";
import { Authors } from "./components/Authors";
import { AuthorPage } from "./components/AuthorPage";
import { Delivery } from "./components/Delivery";
import { Search } from "./components/Search";
import { CategoryContext } from "./context/CategoryContext";
import { CategoryDataProvider } from "./context/CategoryContext";
import { ActualProductDataProvider } from "./context/ActualProductContext";
import { AboutUs } from "./components/AboutUs";


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route
        index
        element=
        {
          <CategoryDataProvider>
            <ActualProductDataProvider>
              <Header/>
                <main className="main">
                  <Intro/>
                  {/* <Contacts/> */}
                  <Search/>
                  {/* <Categories/> */}
                  {/* <Products/> */}
                </main>
              <Footer/>
            </ActualProductDataProvider>

          </CategoryDataProvider>

        }
        />
        <Route path="/admin/*" element={<AdminPanel />} />
        <Route
          path="/products/:productId"
          element={
            <>
              <div>
                <Header />
                <ProductPage />
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/authors"
          element={
            <>
              <div>
                <Header />
                <Authors />
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/authors/:authorId"
          element={
            <>
              <div>
                <Header />
                <AuthorPage />
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/delivery"
          element={
            <>
              <div>
                <Header />
                <Delivery/>
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path="/about-us"
          element={
            <>
              <div>
                <Header />
                <AboutUs/>
              </div>
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  )
}

export default App;
