import { createContext, useState, useEffect, useContext } from 'react'


export const ActualProductContext = createContext(null)

export function ActualProductDataProvider({ children }){
  const [actualProductData, setActualProductData] = useState([])
  // const [actualProductData, setActualProductData] = useContext([])

  return (
    <ActualProductContext.Provider value={{actualProductData, setActualProductData}}>
      {children}
    </ActualProductContext.Provider>
  )

}
