import { List, TextField, Datagrid, Edit, SimpleForm, TextInput, ArrayField, DeleteButton, EditButton, Create } from "react-admin";
import { ReferenceField } from "react-admin";
import { ReferenceManyField } from "react-admin";
import { NumberField } from "react-admin";


export const CategoryList = () => {
    return (
      <List>  
        <Datagrid>  
          <TextField source="id"/>
          <TextField source="label"/>
          <TextField source="slogan"/>
          <ReferenceManyField
            reference="products"
            label="Products"
            target="categoryId">
            <Datagrid rowClick="edit">
              <NumberField source='id' />
              <TextField source='name' />
              <NumberField source="height" />
              <NumberField source="width" />
              <NumberField source="price" />
              <TextField source='color' />
              <TextField source='description' />
              <TextField source='image' />
            </Datagrid>
          </ReferenceManyField>
          <EditButton />
          <DeleteButton />
        </Datagrid>
      </List>
    );
  };

  export const CategoryCreate = () => {
    return (
    <Create>
      <SimpleForm>
        <TextInput source="label"/>
        <TextInput source="slogan"/>
      </SimpleForm>
    </Create>)
    
  }

  export const CategoryEdit = () => {
    return (
      <Edit>
        <SimpleForm>
          <TextInput source="id"/>
          <TextInput source="label"/>
          <TextInput source="slogan"/>
        </SimpleForm>
      </Edit>
    )
  }