import { Admin, Datagrid, List, Resource, ShowGuesser, TextField, UserMenu } from "react-admin";
import { useGetOne, SelectInput } from 'react-admin';
import { authProvider } from "./authProvider";
import { stringify } from "query-string";
import dataProvider from "./dataProvider";
import { productList } from "./product";
import { authorsList } from "./author";
import { ProductCreate } from "./product";
import { productEdit } from "./product";
import { authorEdit } from "./author";
import { AuthorCreate } from "./author";
import { Dashboard } from "./dashBoard";
import { CategoryList, CategoryCreate, CategoryEdit } from "./category";

export const AdminPanel = () => (
  <Admin 
  authProvider={authProvider} 
  dataProvider={dataProvider} 
  dashboard={Dashboard}
  basename="/admin"
  >
    <Resource name="authors" list={authorsList}  edit={authorEdit} create={AuthorCreate}/>
    <Resource name="products" list={productList} edit={productEdit}  create={ProductCreate}/>
    <Resource name="category" list={CategoryList} edit={CategoryEdit} create={CategoryCreate}/>
  </Admin>
);