import './index.scss'
import accept from "../../img/accept.png"
import calendar from "../../img/calendar.png"
import done from "../../img/exam.png"
import car from "../../img/truck.png"
import shop from "../../img/shop.png"
import ship from "../../img/ship.png"
import handshake from "../../img/hand-shake.png"


export function Delivery(){
    return (
      <section className='delivery'>
        <div className="wrapper">
          <h1 className='delivery__buy__label'>Как совершить покупку?</h1>
          <div className="delivery__container">
            <div className="delivery__buy__card">
            <div className="delivery__top">
              <div className="delivery__buy__step">
                <h1 className='delivery__buy-number'>1.</h1>
              </div>
              <img className='delivery__buy__image' src={done}/>
            </div>
              <h1 className='delivery__buy__text'>Оформите заказ</h1>
              <h3 className='delivery__buy__desc'>в телеграмм чате или через сайт</h3>
            </div>

            <div className="delivery__buy__card">
              <div className="delivery__top">
                <div className="delivery__buy__step">
                  <h1 className='delivery__buy-number'>2.</h1>
                </div>
                <img className='delivery__buy__image' src={accept}/>
              </div>
              <h1 className='delivery__buy__text'>Получите подтверждение</h1>
              <h3 className='delivery__buy__desc'>от администрации сайта</h3>
            </div>

            <div className="delivery__buy__card">
            <div className="delivery__top">
              <div className="delivery__buy__step">
                <h1 className='delivery__buy-number'>3.</h1>
              </div>
              <img className='delivery__buy__image' src={calendar}/>  
            </div>
              <h1 className='delivery__buy__text'>Дождитесь посылки</h1>
              <h3 className='delivery__buy__desc'>в назначенный день</h3>
            </div>

            <div className="delivery__buy__card">
            <div className="delivery__top">
              <div className="delivery__buy__step">
                <h1 className='delivery__buy-number'>4.</h1>
              </div>
              <img className='delivery__buy__image' src={car}/>
            </div>
              <h1 className='delivery__buy__text'>Получите Ваш заказ</h1>
              <h3 className='delivery__buy__desc'>заранее выбранным способом</h3>
            </div>
          </div>
          <h1 className='delivery__buy__label'>Способы получения товара</h1>
          <div className="delivery__ship__container">
            <div className="delivery__ship__card">
              <div className="delivery__ship__card__wrapper">
                <div className="delivery__ship__card__header">
                  <img src={shop} className='delivery__ship__card__image'/>
                  <h1 className='delivery__ship__card__label'>В пункт выдачи, постоматы</h1>
                </div>
                <div className="delivery__ship__card__content">
                  <h3 className='delivery__ship__card__content__text'>- Почта России, СДЭК, Boxberry</h3>
                  <h3 className='delivery__ship__card__content__text'>- 5Post</h3>
                  <h3 className='delivery__ship__card__content__text'>- Доставка бесплатно при заказе на сумму от 15000₽</h3>
                </div>
                <div className="delivery__ship__card__extra">
                  <h3 className='delivery__ship__card__content__text'>Точное время доставки будет известно после выбора курьерской службы.</h3>
                </div>
              </div>
            </div>

            <div className="delivery__ship__card">
              <div className="delivery__ship__card__wrapper">
                <div className="delivery__ship__card__header">
                  <img src={ship} className='delivery__ship__card__image'/>
                  <h1 className='delivery__ship__card__label'>Условия доставки курьером</h1>
                </div>
                <div className="delivery__ship__card__content">
                  <h3 className='delivery__ship__card__content__text'>- Яндекс Курьер</h3>
                  <h3 className='delivery__ship__card__content__text'>- Drivee</h3>
                  <h3 className='delivery__ship__card__content__text'>- Доставка бесплатно при заказе на сумму от 25000₽</h3>
                </div>
                <div className="delivery__ship__card__extra">
                  <h3 className='delivery__ship__card__content__text'>Этот способ доставки возможен только в пределах 50 км от МКАД</h3>
                </div>
              </div>
            </div>

            <div className="delivery__ship__card">
              <div className="delivery__ship__card__wrapper">
                <div className="delivery__ship__card__header">
                  <img src={handshake} className='delivery__ship__card__image'/>
                  <h1 className='delivery__ship__card__label'>Условия самовывоза</h1>
                </div>
                <div className="delivery__ship__card__content">
                  <h3 className='delivery__ship__card__content__text'>- Вам сообщат адрес, где выдадут товар</h3>
                  <h3 className='delivery__ship__card__content__text'>- Оплата на месте наличными или банковской картой</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}