import './index.scss'
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';

const loc = document.location;

async function fetchJSON (url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });

  if (!response.ok)
    throw new Error(`Request at ${url} failed`);

  return await response.json();
}


export function ProductPage(){
	const { productId } = useParams()
	const [authorInfo, setAuthorInfo] = useState()
	const [productInfo, setProductInfo] = useState()
	const [authorId, setAuthorId] = useState()

	async function fetchProducts (productId) {
    return await fetchJSON(`https://ccx-store.com/api/products/${productId}`, { headers: {
      "access-control-request-private-network": true
    } })
}
async function fetchAuthor (authorId) {
    return await fetchJSON(`https://ccx-store.com/api/authors/${authorId}`, { headers: {
      "access-control-request-private-network": true
    } })
}

useEffect(() => {
  (async () =>{
    const fetchedProductInfo = await fetchProducts(productId);
    setProductInfo(fetchedProductInfo);

    const fetchedAuthorInfo = await fetchAuthor(fetchedProductInfo.idOfAuthor);
		setAuthorInfo(fetchedAuthorInfo);
  })();
}, []);

	if (productInfo == undefined) {return}
	if (authorInfo == undefined) {return}

	return (
        <main className="main_product">
					<div className="wrapper">
            <div className="picture_and_info">
              <div className="card_big_img">
                <img className="product_pic" src={`https://ccx-store.com/api/get-image/${productInfo.image}`}/>
              </div>
							<div className="info_and_price">
								<h1 className="big_name">{productInfo.name}</h1>
								<h1 className="price">{productInfo.price} ₽</h1>
								<Link to={`../authors/${authorInfo.id}`} style={{ textDecoration: 'none' }}>
									<h2 className="details__author">{authorInfo.firstName} {authorInfo.lastName}</h2>
								</Link>
								<a href="https://t.me/ccx_com" className="but-btn-link">
                  <button className="button_buy">купить</button>
                </a>

							</div>
						</div>
						<h1 className="characteristics">Характеристики</h1>
						<div className="details">
            <pre className="details_concrete">Артикул: {productInfo.id}</pre>
							<pre className="details_concrete">Материалы: {productInfo.color}</pre>
							<pre className="details_concrete">Высота: {productInfo.height} см</pre>
							<pre className="details_concrete">Ширина: {productInfo.width} см</pre>
						</div>
						<h1 className="characteristics">Описание</h1>
						<h2 className="details_concrete">{productInfo.description}</h2>
					</div>
				</main>
    )
}
