import './index.scss'
import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
// import putin from "../../img/putin.jpg"
// import nigga from "../../img/nigga.png"
import human from "../../img/human.png"
import { Link } from 'react-router-dom';

async function fetchJSON(url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });

  if (!response.ok)
    throw new Error(`Request at ${url} failed`);

  return await response.json();
}

export function Authors() {
  const [authors, setAuthors] = useState([])

  async function fetchAuthors() {
    return await fetchJSON(`https://ccx-store.com/api/authors`, {
      headers: {
        "access-control-request-private-network": true
      }
    })
  }

  useEffect(() => {
    (async () => {
      const fetchedAuthorInfo = await fetchAuthors();
      setAuthors(fetchedAuthorInfo);
    })();
  }, []);

  if (authors == undefined) { return }

  return (
    <section className='authors'>
      <div className="wrapper">
        <div className="authors__lbl-wrapper">
          <h1 className='authors__lbl'>Авторы</h1>
        </div>
        <div className="authors__container">
          {authors.map((author) => {
            return (
              <div className="authors__card">
                <div className="authors__local-text-wrapper">
                  <p className="authors__job">{author.job}</p>
                  <div className="authors__img-wrapper">
                    <img src={`https://ccx-store.com/api/get-image/${author.photo}`} className='author__img' />
                  </div>
                  <p className='authors__name'>{author.firstName} {author.lastName}</p>
                  <p className='authors__descr'>{author.info.slice(0, 50)}...</p>
                  <Link to={`../authors/${author.id}`} style={{ textDecoration: 'none' }} key={author.id}>
                    <button className='authors__more-btn'>Подробнее</button>
                  </Link>
                </div>
              </div>

            )
          })}
        </div>
      </div>

    </section>
  )
}
