import "./index.scss"

export function AboutUs(){
  return (
    <div className="wrapper">
      <p className="our-text"><span className="our-text-fat">Союз Современных Художников</span> - это свободное пространство для молодых творческих личностей.
        Мы называем себя «Союзом», потому что мы верим в то,что искусство должно объединять.
        Для художников - это место возможностей и перспектив.
        Для потребителей - это место с доступным искусством.
      </p>
    </div>
  )
}
