import React, { useState, useEffect, useContext } from "react";
import './index.scss'
import burgerBar from "../../img/burger-bar.png"
import classNames from "classnames";
import { CategoryContext } from "../../context/CategoryContext";
import { Link } from 'react-router-dom';
import { SearchBar } from "./SearchBar";
import { ActualProductContext } from "../../context/ActualProductContext";

const loc = document.location;

async function fetchJSON(url, { headers = {}, ...init } = {}) {
  const response = await fetch(url, {
    ...init,
    headers: {
      ...headers,
      accept: "application/json",
    }
  });

  if (!response.ok)
    throw new Error(`Request at ${url} failed`);

  return await response.json();
}


export const DropdownMenu = ({ menuSettings }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState([])
  const categoryData = useContext(CategoryContext)
  const {actualProductData, setActualProductData} = useContext(ActualProductContext)

  // const [currentCategory, setCurrentCategory] = useState("")
  // const [currentAuthor, setCurrentAuthor] = useState("")

  async function fetchCategories() {
    return await fetchJSON(`https://ccx-store.com/api/category`, { headers: {
      "access-control-request-private-network": true
    } })
  }

  function handleCategoryBtn(e) {

    const currentCategory = e.target.textContent
    setActualProductData(categoryData.filter(v => v.label === currentCategory || currentCategory === ""))

  }

  useEffect(() => {
    (async () => {
      const fetchedCategoryInfo = await fetchCategories();
      setCategories(fetchedCategoryInfo);
    })();
    setActualProductData(categoryData)

    function handleClickOutside(event) {
      if (!menuSettings.isMenuOpened) return

      const menuBlock = document.querySelector(".dropdown")
      const burger = document.querySelector(".dropdown__button")
      if (!menuBlock.contains(event.target) && !burger.contains(event.target)) {
        menuSettings.setIsMenuOpened(false)
      }
    }

    document.addEventListener('click', handleClickOutside, false)
  }, [categoryData])

  return (
    <div className={classNames("dropdown")}>
      <div className="dropdown__wrapper">
        <div className="dropdown__body">
          <button className="dropdown__button" type="button" onClick={() => setIsOpen(prevState => !prevState)}>
            <div className="dropdown__container">
              <img src={burgerBar} className="dropdown__icon" />
              <p className="dropdown__label">Категории</p>
            </div>
          </button>
          <ul className={`dropdown__menu ${isOpen ? 'active' : ''}`}>
            {categories.map((category) => {
              return (
                <li className="dropdown__item" key={category.id}>
                  <button onClick={handleCategoryBtn} className="select-btn">{category.label}</button>
                </li>
              )
            })}
          </ul>
        </div>
        <SearchBar />
      </div>


      <section className="products">
        {/* <div className="wrapper"> */}
        {actualProductData.map((category) => {
          return (
            <div id={category.label} key={category.id}>
              {/* <h1 className='products__category-name' key={category.id}>{category.label}</h1> */}
              <h1 className='products__category-name'>{category.label}</h1>
              <h3 className='products__category-slogan'>{category.slogan}</h3>
              <div className="products__container">
                {category.products.map((product) => {
                  return (
                    <Link to={`../products/${product.id}`} style={{ textDecoration: 'none' }} key={product.id}>
                      <div className="products__card">
                        <div className="products__card-background">
                          <img src={`https://ccx-store.com/api/get-image/${product.image}`} className="products__card-image" />
                        </div>
                        <div className="products__card-info">
                          <h2 className="products__card-name">{product.name}</h2>
                          {/* <h3 className="products__card-author">Донская Шура</h3> */}
                          <p className="products__card-params">{product.width}×{product.height}см</p>
                          <h1 className="products__card-params">{product.price} ₽</h1>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          )
        })}
        {/* </div> */}
      </section>
    </div>
  );
};
