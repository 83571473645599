import { List, TextField, SelectInput, Datagrid, Edit, SimpleForm, TextInput, ArrayField, DeleteButton, EditButton, Create } from "react-admin";
import { ReferenceField } from "react-admin";
import { ReferenceManyField } from "react-admin";
import { NumberField } from "react-admin";
import { useState, useEffect } from "react";

export const authorsList = () => {
  return (
    <List>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="firstName"/>
        <TextField source="lastName"/>
        <TextField source="job"/>
        <TextField source="info"/>
        <TextField source="photo"/>

        {/* <ArrayField source="products"/>   */}
        <ReferenceManyField
          reference="products"
          label="Products"
          target="authorId"
        >
          <Datagrid rowClick="edit">
            <NumberField source='id' />
            <TextField source='name' />
            <NumberField source="height" />
            <NumberField source="width" />
            <NumberField source="price" />
            <TextField source='color' />
            <TextField source='description' />
            <TextField source='image' />
          </Datagrid>
        </ReferenceManyField>
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>


  );
};

export const AuthorCreate = (props) => {
  const [images, setImages] = useState([])
  useEffect(() => {
    fetch("https://ccx-store.com/api/img")
      .then(data => data.json())
      .then(res => {setImages(res)})
      .catch(err => console.error(err));
  }, [])

  return (
  <Create>
    <SimpleForm>
      <TextInput source="firstName"/>
      <TextInput source="lastName"/>
      <TextInput source="job"/>
      <TextInput source="info" multiline rows={20}/>
      {
        <SelectInput source="photo" choices={images?.map(image => ({id: image, name: image}))}/>
      }
    </SimpleForm>
  </Create>)
}

export const authorEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput source="id"/>
        <TextInput source="firstName"/>
        <TextInput source="lastName"/>
        <TextInput source="job"/>
        <TextInput source="info"/>
        <TextInput source="photo"/>
      </SimpleForm>
    </Edit>
  )
}

